import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import Logo from "../images/about-logo.svg"
import Img1 from "../images/about-1.jpg"
import Partner1 from "../images/partner-1.png"
import Partner2 from "../images/partner-2.png"
import Partner3 from "../images/partner-3.png"
import Partner4 from "../images/partner-4.png"
import Partner5 from "../images/partner-5.png"
import { Footer } from "../components/Footer/Footer"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../reducers/rootReducer"

const About = () => {
  const userName = typeof window !== 'undefined' && localStorage.getItem('name');
  const componentRef = useRef<any>(null);
  const [signed, setSigned] = useState(false);

  const {items}: any = useSelector((state: RootState) => state.loginSlice);

  useEffect( () => {
    if(items.status) {
      setSigned(true)
    }
  },[items]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
      {/* <Header isSignIn={signed} /> */}

        <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[80px] pb-[60px]">
          <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
            <div className="col-span-12 mb-[20px] lg:mb-[60px]">
              <div className="lg:flex relative lg:items-center lg:justify-between p-[15px] lg:pl-[30px] lg:pt-[40px] lg:pr-[400px] lg:pb-[40px] bg-gradient-about  rounded-2xl ">
                <div className="lg:absolute lg:top-[-50px] lg:right-0 mb-[30px] lg:mb-0">
                  <img src={Img1} alt="" className="w-[150px] md:w-[250px] lg:w-[300px] lg:m-auto rounded-full"/>
                </div>
                <div className="w-full">
                  <h3 className="ff-cg--bold text-white text-[35px] leading-none mb-2">About Us</h3>
                  <p className="text-white text-[26px] leading-normal font-normal">Workforce Forward is powered by University of Maryland Global Campus (UMGC), one of the largest online universities in the world.</p>
                </div>
              </div>
            </div>
            <div className="col-span-12 mb-[40px]">
              <div className="lg:flex items-center justify-between pt-[40px] pl-[30px] pr-[30px] pb-[40px] bg-[#222222] rounded-2xl">
                <div className="w-full">
                  <h3 className="ff-cg--bold text-white text-[25px] leading-none mb-2">Made Possible by Our Partners</h3>
                  <p className="text-[#cccccc] text-base text-[16px] leading-normal mb-2">Workforce Forward curates skills-focused content into a central marketplace to offer a comprehensive set of micro-credentialing courses and programs. To achieve this, we partner with industry-leading providers who are connected to the needs of today’s workforce, enabling learners and organizations worldwide to tap into leading-edge skills required of tomorrow’s global economy.</p>
                  <div className="md:flex mt-[20px] gap-4 mb-[20px] md:justify-between flex-wrap">
                    <div className="md:min-w-[48%] md:max-w-[48%] lg:min-w-[18%] lg:max-w-[18%] border border-[#595959] flex items-center justify-center rounded-xl p-4 mb-[20px] md:mb-0">
                      <img src={Partner1} alt="" />
                    </div>
                    <div className="md:min-w-[48%] md:max-w-[48%] lg:min-w-[18%] lg:max-w-[18%] border border-[#595959] flex items-center justify-center rounded-xl p-4 mb-[20px] md:mb-0">
                      <img src={Partner2} alt="" />
                    </div>
                    <div className="md:min-w-[48%] md:max-w-[48%] lg:min-w-[18%] lg:max-w-[18%] border border-[#595959] flex items-center justify-center rounded-xl p-4 mb-[20px] md:mb-0">
                      <img src={Partner3} alt="" />
                    </div>
                    <div className="md:min-w-[48%] md:max-w-[48%] lg:min-w-[18%] lg:max-w-[18%] border border-[#595959] flex items-center justify-center rounded-xl p-4 mb-[20px] md:mb-0">
                      <img src={Partner4} alt="" />
                    </div>
                    <div className="md:min-w-[48%] md:max-w-[48%] lg:min-w-[18%] lg:max-w-[18%] border border-[#595959] flex items-center justify-center rounded-xl p-4 mb-[20px] md:mb-0">
                      <img src={Partner5} alt="" />
                    </div>
                  </div>
                  <p className="text-[#cccccc] text-base text-[16px] leading-normal">From career exploration to building in-depth expertise within today’s most in-demand fields, Workforce Forward meets learners where they are.</p>   
                </div>
              </div>
            </div>

            <div className="col-span-12">
                <div className="lg:flex items-center justify-between">
                    <div className="w-full">
                        <img src={Logo} alt="" className="w-[120px] m-auto md:m-0 md:float-left mb-[20px] md:mb-0 md:mr-[15px]"/>
                        <h2 className="ff-cg--bold text-[45px]">Powered by UMGC</h2>
                        <p className="text-[20px] ff-cg--light"><span className="ff-cg--medium">University of Maryland Global Campus</span> was founded more than 70 years ago specifically to serve the higher education needs of working adults and servicemembers
                        <br />
                        <br />
                        Today, UMGC continues its global tradition with online and hybrid courses, more than 140 classroom and service locations worldwide, and more than 90 degrees, certificates, and specializations backed by the reputation of a state university and the University System of Maryland. For more information, visit <a href="#" className="text-[#da1a32] text-underline">umgc.edu</a></p>
                    </div>
                </div>
            </div>

          </div>
        </section>
      </div>
      <Footer/>
    </Layout>
  )
}

export default About;
export { Head } from "../components/Layout/Head";
